import React from 'react'
import styled from 'styled-components'
import { Flex, Heading, Text, Link } from '@pancakeswap/uikit'
import { useTranslation } from 'contexts/Localization'
import ConnectWalletButton from 'components/ConnectWalletButton'
import Container from 'components/Layout/Container'
import { useWeb3React } from '@web3-react/core'
import SunburstSvg from './SunburstSvg'
import CompositeImage from './CompositeImage'

const BgWrapper = styled.div`
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
`

const StyledSunburst = styled(SunburstSvg)`
  height: 350%;
  width: 350%;

  ${({ theme }) => theme.mediaQueries.xl} {
    height: 400%;
    width: 400%;
  }
`

const Wrapper = styled(Flex)`
  z-index: 1;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
`

const FloatingPancakesWrapper = styled(Container)`
  overflow: hidden;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  visibility: hidden;

  ${({ theme }) => theme.mediaQueries.md} {
    visibility: visible;
  }
`

const TopLeftImgWrapper = styled(Flex)`
  position: absolute;
  left: 0;
  top: 0;
`

const BottomRightImgWrapper = styled(Flex)`
  position: absolute;
  right: 0;
  bottom: 0;
`

const topLeftImage = {
  path: '/images/home/flying-pancakes/',
  attributes: [
    { src: '1-bottom', alt: 'Pancake flying on the bottom' },
    { src: '1-left', alt: 'Pancake flying on the left' },
    { src: '1-top', alt: 'Pancake flying on the top' },
  ],
}

const bottomRightImage = {
  path: '/images/home/flying-pancakes/',
  attributes: [
    { src: '2-bottom', alt: 'Pancake flying on the bottom' },
    { src: '2-top', alt: 'Pancake flying on the top' },
    { src: '2-right', alt: 'Pancake flying on the right' },
  ],
}

const Footer = () => {
  const { t } = useTranslation()
  const { account } = useWeb3React()

  return (
    <>
     <section className='footerTop'>
       <div className='container'>
         <div className="flexFooter align-items-end">
           <div className='gridFooter widthLarge'>
             <div className='footerLogo'>
                <img src="/images/wsum.png" alt="" className='img-fluid' />
             </div>
             <p>Full-service multi-currency banking, asset management, trading and advisory firm supporting blockchain companies. We are one of the only regulated financial firms that support blockchain and digital assets. </p>
           </div>
           <div className='gridFooter WidthSmall'>
             <h3>Company</h3>
              <ul className='flex_grid_foter'>
                <li><a href='/about-us'>About Us</a></li>
                <li><a href='/'>Contact Us</a></li>
              </ul>
           </div>
     
       
         </div>
       </div>
     </section>
     <section className='footerBottom'>
       <div className='container'>
        <div className='flex_foter_bo'>

        <p className="text-right">Copyright &copy; 2023 JOYFUN - All Rights Reserved.</p>

        </div>
       </div>
     </section>
    </>
  )
}

export default Footer
